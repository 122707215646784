#buscador,
#unalTop .navigation .buscador {
	-webkit-border-radius: 5px; -moz-border-radius: 5px; -ms-border-radius: 5px; -o-border-radius: 5px; border-radius: 5px; background: none; color: white; margin: 0; overflow: hidden; width: 200px; right: 85px; top: 36px; height: 30px; z-index: 2;
}

#buscador .gscb_a,
#unalTop .navigation .buscador .gscb_a {
	line-height: 20px;
}

#buscador .gsc-search-box .gsc-input>input:hover,
#buscador .gsc-input-box-hover,
#unalTop .navigation .buscador .gsc-search-box .gsc-input>input:hover,
#unalTop .navigation .buscador .gsc-input-box-hover {
	border: 0; -moz-box-shadow: none; -webkit-box-shadow: none; box-shadow: none; outline: 0;
}

#buscador .gsc-control-searchbox-only .gsc-input,
#unalTop .navigation .buscador .gsc-control-searchbox-only .gsc-input {
	background-color: #777; background-image: none!important; width: 100%; padding: 0;
}

#buscador .gsc-control-searchbox-only .gsc-input input,
#unalTop .navigation .buscador .gsc-control-searchbox-only .gsc-input input {
	background: none !important; text-indent: 0 !important; color: #ddd; display: inline; font-size: 0.85em; width: 100%!important; padding: 0 10px!important;
}

#buscador .gsc-control-searchbox-only .gsc-input input::placeholder,
#unalTop .navigation .buscador .gsc-control-searchbox-only .gsc-input input::placeholder {
	color: #ddd;
}

#buscador .gsc-control-searchbox-only .gsc-input td.gsib_b,
#unalTop .navigation .buscador .gsc-control-searchbox-only .gsc-input td.gsib_b {
	display: none;
}

#buscador .gsc-control-searchbox-only .gsc-search-box,
#unalTop .navigation .buscador .gsc-control-searchbox-only .gsc-search-box {
	height: 30px; margin: 0; position: relative;
}

#buscador .gsc-input-box-focus,
#unalTop .navigation .buscador .gsc-input-box-focus {
	-webkit-box-shadow: none; -moz-box-shadow: none; -o-box-shadow: none; box-shadow: none;
}

#buscador .gsc-search-button,
#unalTop .navigation .buscador .gsc-search-button {
	-webkit-border-radius: 0; -moz-border-radius: 0; -ms-border-radius: 0; -o-border-radius: 0; border-radius: 0; background-color: #94b43b!important; border: 0 none; -webkit-box-shadow: none; -moz-box-shadow: none; -o-box-shadow: none; box-shadow: none; cursor: pointer; float: right; height: 30px; padding: 0; width: 30px; margin-left: 0 !important;
}

#buscador .gsc-search-button input.gsc-search-button,
#unalTop .navigation .buscador .gsc-search-button input.gsc-search-button {
	border: 0; -webkit-box-shadow: none; -moz-box-shadow: none; -o-box-shadow: none; box-shadow: none; margin: 0; outline: 0; padding: 0; height: 30px; width: 30px;
}

#buscador .gsc-search-button button.gsc-search-button-v2 svg,
#unalTop .navigation .buscador .gsc-search-button button.gsc-search-button-v2 svg {
	width: 16px !important; height: 16px !important; margin: 7px !important;
}

#buscador .gsc-input-box,
#unalTop .navigation .buscador .gsc-input-box {
	background: #777; border: 0; color: white; padding: 0;
}

table.gsc-completion-container {
	font-family: 'Ancizar sans' !important; font-weight: normal !important;
}

.gsc-control-searchbox-only.gsc-control-searchbox-only-es {
	width: 80%;
	margin-left: 10%;
}


header {
  background: linear-gradient(0deg, rgba(72,68,68,.9058823529), rgba(72,68,68,.9058823529)), url('https://unal.edu.co/fileadmin/_processed_/3/7/csm_UNAL_abri-08-24-02_fed164965d.jpg');
}

.menuPrincipal {
  float: right;
  margin-right: 3rem;
}